import React from 'react';
// import {useTheme} from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
export const ConsultationButton = ({ handeFormOpen }) => {
//   const theme = useTheme();
//   const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className="phone_wrap">
        <span onClick={handeFormOpen} className="consultButton">Free Consultation</span>
    </div>
  );
};

